import { createBrowserRouter } from 'react-router-dom';
import BaseLayout from './components/base-layout';
import SuspenseLoader from './components/loader/suspense-loader';
import { lazy, Suspense } from 'react';

const Loader = (Component: any) => (props: any) =>
(
    <Suspense fallback={<SuspenseLoader />}>
        <Component {...props} />
    </Suspense>
);

const PageNotFound = Loader(lazy(() => import("./components/page-not-found")));

const HomePage = Loader(lazy(() => import("./contents/homepage")));
const Publish = Loader(lazy(() => import("./contents/homepage/publish")));
const RightWatchContent = Loader(lazy(() => import("./contents/right-no-watch")));
const LeftWatchContent = Loader(lazy(() => import("./contents/left-no-watch")));
const RightWatch = Loader(lazy(() => import("./contents/right-watch")));
const LeftWatch = Loader(lazy(() => import("./contents/left-watch")));
const PlayVideo = Loader(lazy(() => import("./contents/play-video")));
const Video1 = Loader(lazy(() => import("./contents/videos/video1")));
const Video2 = Loader(lazy(() => import("./contents/videos/video2")));
const Video3 = Loader(lazy(() => import("./contents/videos/video3")));
const Video4 = Loader(lazy(() => import("./contents/videos/video4")));
const Video5 = Loader(lazy(() => import("./contents/videos/video5")));
const Video6 = Loader(lazy(() => import("./contents/videos/video6")));
const NoWatch1 = Loader(lazy(() => import("./contents/no-watch/1-no-watch")));
const Watch1 = Loader(lazy(() => import("./contents/watch/1-watch")));
const NoWatch2 = Loader(lazy(() => import("./contents/no-watch/2-no-watch")));
const Watch2 = Loader(lazy(() => import("./contents/watch/2-watch")));
const NoWatch3 = Loader(lazy(() => import("./contents/no-watch/3-no-watch")));
const Watch3 = Loader(lazy(() => import("./contents/watch/3-watch")));
const NoWatch4 = Loader(lazy(() => import("./contents/no-watch/4-no-watch")));
const Watch4 = Loader(lazy(() => import("./contents/watch/4-watch")));
const NoWatch5 = Loader(lazy(() => import("./contents/no-watch/5-no-watch")));
const Watch5 = Loader(lazy(() => import("./contents/watch/5-watch")));


export const router = createBrowserRouter([
    {
        path: '/',
        element: <BaseLayout />,
        errorElement: <PageNotFound />,
        children: [
            { index: true, element: <HomePage /> },
            { path: "/publish", element: <Publish /> },
            { path: "/right-no-watch", element: <RightWatchContent /> },
            { path: "/right-watch", element: <RightWatch /> },
            { path: "/left-no-watch", element: <LeftWatchContent /> },
            { path: "/left-watch", element: <LeftWatch /> },
            { path: "/play-video", element: <PlayVideo /> },
            { path: "/video1", element: <Video1 /> },
            { path: "/video2", element: <Video2 /> },
            { path: "/video3", element: <Video3 /> },
            { path: "/video4", element: <Video4 /> },
            { path: "/video5", element: <Video5 /> },
            { path: "/video6", element: <Video6 /> },
            { path: "/1-no-watch", element: <NoWatch1 /> },
            { path: "/1-watch", element: <Watch1 /> },
            { path: "/2-no-watch", element: <NoWatch2 /> },
            { path: "/2-watch", element: <Watch2 /> },
            { path: "/3-no-watch", element: <NoWatch3 /> },
            { path: "/3-watch", element: <Watch3 /> },
            { path: "/4-no-watch", element: <NoWatch4 /> },
            { path: "/4-watch", element: <Watch4 /> },
            { path: "/5-no-watch", element: <NoWatch5 /> },
            { path: "/5-watch", element: <Watch5 /> },
        ]
    },
    {
        path: "*",
        element: <PageNotFound />,
        errorElement: <PageNotFound />,
    },
]);
