import { createSlice } from "@reduxjs/toolkit";

// quiz questions
export interface IQuestion {
    id: number;
    question: string;
    optionA: string;
    optionB: string;
    optionC: string;
    optionD: string;
    correctAnswer: string;
    image: string;
}

// random quiz 
export interface IRandomQuestions {
    id: number;
    question: string;
    optionA: string;
    optionB: string;
    optionC: string;
    optionD: string;
    correctAnswer: string;
    questionNo: number;
    isCorrect: boolean;
    timeTaken: number;
}

interface IUSER {
    name: string;
    email: string;
    phoneNumber: string;
}

interface IRoot {
    user?: IUSER,
    totalQuestions: number,
    quizTimer: number,
}

const initialState: IRoot = {
    user: {
        name: "",
        email: "",
        phoneNumber: "",
    },
    totalQuestions: 10,
    quizTimer: 10,
};

const slice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUser(state, action) {
            state.user = action.payload;
        },
    },
});

export const UserActions = slice.actions;

export const reducer = slice.reducer;

export default slice;
