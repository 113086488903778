import React, { FC, ReactNode } from "react";
import PropTypes from "prop-types";
import { Outlet } from "react-router-dom";
import { Box, styled } from "@mui/system";
import { subscribeToTopic } from "../../utils/mqtt";

const Wrapper = styled(Box)(() => ({
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
}));


interface IBaseLayoutProps {
    children?: ReactNode;
}

const BaseLayout: FC<IBaseLayoutProps> = () => {

    React.useEffect(() => {
        subscribeToTopic('state');
    }, []);

    return (
        <Wrapper>
            <Outlet />
        </Wrapper>
    );
};

BaseLayout.propTypes = {
    children: PropTypes.node,
};

export default BaseLayout;
