import mqtt, { MqttClient, IClientOptions, IClientPublishOptions } from 'mqtt';

// Define MQTT connection options
const mqttOptions: IClientOptions = {
    host: process.env.REACT_APP_MQTT_CLIENT_URL, // Replace with your MQTT broker host
    port: Number(process.env.REACT_APP_MQTT_CLIENT_PORT), // Default MQTT port
    protocol: 'mqtt',
    // Add other options like username and password if required
};

// Create MQTT client instance
let client: MqttClient = mqtt.connect(mqttOptions);

// Function to handle MQTT reconnection
const reconnect = () => {
    if (!client.connected) {
        /*eslint-disable-next-line*/
        console.log('Attempting to reconnect to MQTT broker...');
        client = mqtt.connect(mqttOptions); // Reconnect by creating a new client instance
    }
};

// Handle MQTT connection events
client.on('connect', () => {
    /*eslint-disable-next-line*/
    console.log('Connected to MQTT broker 😀');
});

client.on('error', (error) => {
    /*eslint-disable-next-line*/
    console.error('MQTT error:', error);
});

client.on('close', () => {
    /*eslint-disable-next-line*/
    console.log('MQTT connection closed');
    reconnect(); // Attempt reconnection when the connection is closed
});

// Subscribe to a MQTT topic
export const subscribeToTopic = (topic: string): void => {
    client.subscribe(topic, (err) => {
        if (err) {
            /*eslint-disable-next-line*/
            console.error('Error subscribing to MQTT topic:', err);
        } else {
            /*eslint-disable-next-line*/
            console.log('Subscribed to MQTT topic:', topic);
        }
    });
};

// Publish a message to a MQTT topic
export const publishMessage = (topic: string, message: string, options?: IClientPublishOptions): void => {
    client.publish(topic, message, options, (err) => {
        if (err) {
            /*eslint-disable-next-line*/
            console.error('Error publishing message to MQTT topic:', err);
        } else {
            /*eslint-disable-next-line*/
            console.log('Message published to MQTT topic:', topic, message.toString());
        }
    });
};

// Handle incoming messages
client.on('message', (topic: any, message: any) => {
    // 1-no-watch
    // 1-watch
    // 2-no-watch
    // 2-watch
    // 3-no-watch
    // 3-watch
    //4-no-watch
    // 4-watch
    // 5-no-watch
    // 5-watch
    /*eslint-disable-next-line*/
    console.log(`Received message on topic ${topic}: ${message.toString()}`);
    if (topic === "state") {
        window.location.href = message.toString();
    }

});

export default client; // Export MQTT client instance
